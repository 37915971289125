import { FC } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { ArticlePage, ArticlesPage } from '../../../articles/components';
import { SelectedCategoryPage } from '../../../categories';
import {
    ChatHistoryPage,
    ChatHistorySessionPage,
} from '../../../chat-history/components';
import { ChatWithExpert } from '../../../core-ui';
import { ErrorPage } from '../../../error-handling/ErrorPage';
import { GuidePage, GuidesPage } from '../../../guides';
import { RoutePath } from '../../../navigation';
import { SecurityCenterPage } from '../../../security-center';
import { TodaysTipPage } from '../../../tips';
import { isInV2 } from '../../../utils/isInV2';
import VideoPage from '../../../video/components/VideoPage';
import { RedirectManager } from './RedirectManager';
import { Switch } from './Switch';

export const Routes: FC = () => {
    const isV2 = isInV2();
    const version = isV2 ? 'v2' : '';

    return (
        <BrowserRouter basename={`/${version}`}>
            <Switch>
                <Route
                    exact
                    path={RoutePath.Articles}
                    component={ArticlesPage}
                />
                <Route
                    exact
                    path={`${RoutePath.Articles}/:id`}
                    component={ArticlePage}
                />
                <Route
                    exact
                    path={RoutePath.ChatHistory}
                    component={ChatHistoryPage}
                />
                <Route
                    exact
                    path={`${RoutePath.ChatHistory}/:id`}
                    component={ChatHistorySessionPage}
                />
                <Route exact path={RoutePath.Guides} component={GuidesPage} />
                <Route
                    exact
                    path={`${RoutePath.Guides}/:id`}
                    component={GuidePage}
                />
                <Route
                    exact
                    path={RoutePath.Home}
                    component={SecurityCenterPage}
                />
                <Route exact path={RoutePath.Video} component={VideoPage} />
                <Route path={RoutePath.Error} component={ErrorPage} />
                <Route
                    exact
                    path={RoutePath.SecurityCenter}
                    component={SecurityCenterPage}
                />
                <Route
                    exact
                    path={`${RoutePath.Category}/:category`}
                    component={SelectedCategoryPage}
                />
                <Route path={RoutePath.TodaysTip} component={TodaysTipPage} />
            </Switch>
            <RedirectManager />
            {isV2 ? null : <ChatWithExpert />}
        </BrowserRouter>
    );
};
